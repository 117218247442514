import React, { Suspense } from 'react';
//import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { useTranslation, } from 'react-i18next';

import Header from '@Components/HeaderAccount';
import Navigator from '@Components/AccountNav';
import Alert from 'react-bootstrap/Alert';

import Modal from 'react-bootstrap/Modal';
import { IoLogoWhatsapp, IoCheckmarkCircle, IoHelpCircle } from "react-icons/io5";
import Button from 'react-bootstrap/Button';


import Table from 'react-bootstrap/Table';

import moment from 'moment-timezone';
import 'moment/locale/pt';
import 'moment/locale/es';

const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}
const fn_formatPrice = (price, currency, locale) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency

    }).format(price);

    return price_formatted;

};


const Message = ({
    token
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <>

        <p className='text-center'>{t('account.request_quote')}</p>
        <a href={`https://wa.me/18634519550?text=${t('common.support')}`} target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

        <Container
            className='mt-3'
        >
            <Button
                onClick={() => navigate(`/account/${token}/quote`)}
            >{t('account.quote_request_form')}</Button>
        </Container>

    </>
}
const AccountPackages = () => {
    moment.locale(localStorage.getItem("lng") || "en");
    const navigate = useNavigate();
    const routeParams = useParams();
    const {
        token,
    } = routeParams;
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);



    const [show2, setShow2] = React.useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const {
        token_info,
    } = dataReducer;

    return <Suspense><Container
        style={{
            padding: 0
        }}
    >
        <Header />
        {token_info && !token_info?.success ?
            <div
                className='div-col'
            >
                <Alert
                    className='alert-messages'

                >
                    <p>
                        {t(`account.${token_info.msg}`)}

                    </p>
                    <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

                </Alert>
            </div>

            :
            <>


                <Container

                    style={{
                        padding: 0,
                        maxWidth: 650,
                    }}
                >
                    <Navigator
                        activeKey="packages"
                    />

                    {token_info?.data?.orders?.length > 0 &&
                        <div className='div-col' style={{ paddingTop: 5, paddingBottom: 10 }}>
                            <Button size="sm" variant='outline-secondary' onClick={handleShow2}>
                                <IoHelpCircle size={25} />
                                {t('account.quotes')}
                            </Button>

                        </div>
                    }

                    {!token_info &&
                        <div className='div-col-margin-20'>
                            <Spinner animation="border" variant="warning" size="lg" />
                        </div>
                    }

                    {token_info?.data?.orders?.length > 0 ?

                        <Container>
                            <Table
                                striped
                                small
                            >


                                <tbody>

                                    {token_info?.data?.orders?.map((quote, index) => {
                                        return <tr
                                            key={index}

                                        >

                                            <td>
                                                {quote?.autopart_name}
                                                <br />
                                                <small style={{ color: 'blue' }}>{titleCase(moment(quote.quote_datetime).format("dddd, MMMM DD HH:mm"))}</small>
                                                <br />
                                                {quote?.autopart_number}
                                                <br />
                                                {quote?.autopart_fitment}

                                            </td>
                                            <td>
                                                <b>{fn_formatPrice(quote?.quote_amount, 'USD', 'en-US')}</b>
                                                {quote.payment_authorized_dt === null
                                                    ?
                                                    <div

                                                    >
                                                        <Button variant="primary"
                                                            style={{
                                                                width: 120
                                                            }}
                                                            onClick={() => {
                                                                navigate(`/a/${token}/q/${quote?.quote_id}`)
                                                            }}
                                                        >{t('common.details')}</Button>
                                                    </div>
                                                    :
                                                    <div  >
                                                        <IoCheckmarkCircle color="green" size={20} />&nbsp;{t('account.order_placed')}
                                                        <br />
                                                        <small style={{ color: 'blue' }}>{titleCase(moment(quote.payment_authorized_dt).format("dddd, MMMM DD HH:mm"))}</small>
                                                    </div>

                                                }
                                            </td>

                                        </tr>

                                    }
                                    )}
                                </tbody>

                            </Table>
                        </Container>
                        :
                        <Alert
                            style={{
                                margin: 'auto'
                            }}
                            className='alert-messages'

                        >
                            <Message token={token} />
                        </Alert>
                    }



                </Container>
            </>}


        <Modal size="sm" show={show2} centered onHide={handleClose2} animation={false}>
            <Modal.Header closeButton>
                <img
                    src="/alaniO.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Alani360 logo"
                />
                <Modal.Title>ALANI Garage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='div-col'>
                    <Message
                        token={token}
                    />
                </div>
            </Modal.Body>



        </Modal>

    </Container>
    </Suspense >

}
export default AccountPackages;