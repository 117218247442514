import React from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


const Address = ({
    token
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        token_info,
    } = dataReducer;

    //console.log(token_info)

    const [email, setEmail] = React.useState(token_info?.data?.account?.email || "");
    const [address, setAddress] = React.useState(token_info?.data?.account?.address || "");
    const [address2, setAddress2] = React.useState(token_info?.data?.account?.address2 || "");
    const [city, setCity] = React.useState(token_info?.data?.account?.city || "");
    const [state, setState] = React.useState(token_info?.data?.account?.state || "");
    const [zip, setZip] = React.useState(token_info?.data?.account?.zip || "");

    React.useEffect(() => {
        //if anything changes, update the account fields
        dispatch({
            type: 'ACCOUNT_FIELDS_UPDATE',
            data: {
                address,
                address2,
                city,
                state,
                zip,
                email
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        //if anything changes, update the account fields
        dispatch({
            type: 'ACCOUNT_FIELDS_UPDATE',
            data: {
                address,
                address2,
                city,
                state,
                zip,
                email
            }
        })
    }, [email, address, address2, city, state, zip]); // eslint-disable-line react-hooks/exhaustive-deps


    return <div

        style={{
            width: 300,
            marginBottom: 10
        }}
    >
        <Form.Group className="mt-1"
        >
            <Form.Label className="mb-0">{t('account.Address')}</Form.Label>
            <Form.Control
                type="text"
                name="address"
                style={{ color: "black" }}
                value={address}
                placeholder={t("account.Address1")}
                onChange={(evt) => {
                    /* setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    */
                    setAddress(evt.target.value)
                    //if (fnNoAddress) fnNoAddress();
                }}
                OnBlur={() => {
                    //setSelected(false);
                    //fnSelectAddress(value)
                }}
            //loading={isPlacePredictionsLoading}
            />
            <Form.Control
                className='mt-1'
                type="text"
                name="address2"
                style={{ color: "black" }}
                value={address2}
                placeholder={t("account.Address2")}
                onChange={(evt) => {
                    /* setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    */
                    setAddress2(evt.target.value)
                    //if (fnNoAddress) fnNoAddress();
                }}
                OnBlur={() => {
                    //setSelected(false);
                    //fnSelectAddress(value)
                }}
            //loading={isPlacePredictionsLoading}
            />
        </Form.Group>
        <Form.Group
            className="mt-1"

        >

            <Form.Control
                disabled
                className='mb-1'
                type="text"
                name="city"
                style={{ color: "black" }}
                value={city}
                placeholder={t("account.City")}
                onChange={(evt) => {
                    /* setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    */
                    setCity(evt.target.value);
                    //if (fnNoAddress) fnNoAddress();
                }}
                OnBlur={() => {
                    //setSelected(false);
                    //fnSelectAddress(value)
                }}
            //loading={isPlacePredictionsLoading}
            />
            <Form.Control
                className='mb-1'
                type="text"
                name="state"
                style={{ color: "black", width: 100 }}
                value={state}
                disabled
                placeholder={t("account.State")}
                onChange={(evt) => {
                    /* setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    */
                    setState(evt.target.value);
                    //if (fnNoAddress) fnNoAddress();
                }}
            //loading={isPlacePredictionsLoading}
            />

            <Form.Control
                disabled
                className='mb-1'
                type="text"
                name="CEP"
                style={{ color: "black", width: 150 }}
                value={zip}
                placeholder={t("account.Zip")}
                onChange={(evt) => {
                    /* setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    */
                    setZip(evt.target.value);
                    //if (fnNoAddress) fnNoAddress();
                }}
                OnBlur={() => {
                    //setSelected(false);
                    //fnSelectAddress(value)
                }}
            //loading={isPlacePredictionsLoading}
            />

        </Form.Group>
        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">Email</Form.Label>
            <Form.Control
                type="text"
                name="email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value.toLowerCase())
                }}
            />
        </Form.Group>

    </div >
}

export default Address;
