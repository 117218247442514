import React, { Suspense, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Header from '@Components/Header';
import Footer from '@Components/Footer';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';

import { IoLogoWhatsapp } from "react-icons/io5";
const Page = () => {
    const { t, } = useTranslation();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalContent, setModalContent] = useState("get_started")

    const routeParams = useParams();
    const {
        token
    } = routeParams;


    return <Suspense><Container
        style={{
            padding: 0
        }}
    >
        <Header />
        <Container

        >

            <div
                className='d-flex flex-column justify-content-center align-items-center'
                style={{ paddingTop: 20, }}
            >

                <center>
                    <Trans i18nKey="home.blurb3" />
                </center>
                <div style={{ marginTop: 20, marginBottom: 40, position: 'relative', height: 90, }} className='alani-header'>
                    <div>ALANI GARAGE</div>
                    <img
                        src='/by_alani.jpg'
                        alt='Alani Garage'
                        style={{ width: 90, right: 0, bottom: 0, position: 'absolute' }}
                    />
                </div>

                <center>
                    <Trans i18nKey="home.blurb4" />
                </center>

                <Button onClick={() => {
                    setModalContent("get_started");
                    handleShow();

                }} size="lg" variant="warning">{t('home.getStarted')}</Button>

                <p style={{ textAlign: 'center', margin: 20 }}>
                    <Link to={`/auto-brands/${token || ""}`}  ><Trans i18nKey="home.blurb1" /></Link>
                    <br />
                    <Trans i18nKey="home.blurb2" />
                </p>




                <Modal size={modalContent === "get_started" ? "md" : "lg"} show={show} centered onHide={handleClose} animation={false}>

                    {modalContent === "get_started" &&
                        <>
                            <Modal.Header closeButton>
                                <img
                                    src="/alaniO.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    alt="Alani360 logo"
                                />
                                <Modal.Title>ALANI Garage</Modal.Title>
                            </Modal.Header>


                            <Modal.Body>
                                <center>

                                    <Trans i18nKey="home.getStarted2" />
                                    <br /><br />
                                    <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" size={14} style={{ textShadow: '2px', color: 'blue' }}   ><IoLogoWhatsapp />&nbsp;+18634519550</a>
                                    <br /><br />
                                </center>
                            </Modal.Body>
                        </>
                    }

                    {modalContent === "video" &&
                        <Modal.Body
                            style={{ padding: 0, }}
                        >
                            <div style={{
                                minHeight: 100, minWidth: 300, padding: '56.25% 0 0 0', position: 'relative', backgroundColor: '#000', backgroundImage: 'url("/images/alani.png")',

                                backgroundRepeat: 'no-repeat',
                                backgroundAttachment: 'fixed',
                                backgroundPosition: 'center',
                                backgroundSize: '320px',
                            }}>
                                <iframe src="https://player.vimeo.com/video/797720105?h=7cf8fec9e7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                    title="ALANI Garage"></iframe>
                            </div>
                        </Modal.Body>
                    }

                </Modal>



                <Button
                    style={{ width: 80, }}
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                        setModalContent("video");
                        handleShow();

                    }}
                >Video</Button>
            </div>


        </Container>
    </Container>
        <Footer></Footer>
    </Suspense >

}
export default Page;