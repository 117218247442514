import React from 'react';
import Container from 'react-bootstrap/Container';

import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import { api } from '../api'


const PaymentRedirect = (

) => {
    const routeParams = useParams();
    const {
        token,
        quote_id
    } = routeParams;

    //const dataReducer = useSelector((state) => state.dataReducer);
    //const dispatch = useDispatch();
    //const navigate = useNavigate();

    const [fetching, setFetching] = React.useState(false);




    const fnFetchPayment = async () => {
        setFetching(true);
        let res = await api.payment_fetch({
            payload: {
                token,
                quote_id
            }
        });
        setFetching(false);
        window.location.href = res?.data?.url_redirect_db;

    }

    React.useLayoutEffect(() => {
        if (fetching) return;
        fnFetchPayment();

    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    if (fetching) return <Container
        className='div-col'
        style={{
            marginTop: 50
        }}
    >
        <Spinner size='lg' /> &nbsp;   <img
            src="/alaniO.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Alani360 logo"
        />
    </Container>



    return <Container
        style={{
            padding: 0
        }}
    >



    </Container>



}
export default PaymentRedirect;