import React from 'react';
//import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Header from '@Components/HeaderAccount';
import AccountNav from '@Components/AccountNav';
import Spinner from 'react-bootstrap/Spinner';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { IoLogoWhatsapp, } from "react-icons/io5";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const AccountDetails = ({
    token,
    onCancel
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        token_info,
        api_result
    } = dataReducer;
    console.log(token_info)
    const [firstName, setFirstName] = React.useState(token_info?.data?.account?.firstName || "");
    const [lastName, setLastName] = React.useState(token_info?.data?.account?.lastName || "");
    const [email, setEmail] = React.useState(token_info?.data?.account?.email || "");
    const [address, setAddress] = React.useState(token_info?.data?.account?.address || "");
    const [address2, setAddress2] = React.useState(token_info?.data?.account?.address2 || "");
    const [city, setCity] = React.useState(token_info?.data?.account?.city || "");
    const [state, setState] = React.useState(token_info?.data?.account?.state || "");
    const [zip, setZip] = React.useState(token_info?.data?.account?.zip || "");

    React.useEffect(() => {
        dispatch({
            type: 'API_RESULT_SET',
            data: null
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!token_info) return null;

    return <>

        <Row className="mb-3">
            <Form.Group as={Col} xxs="6" controlId="validationFormik01">
                <Form.Label>{t('account.FirstName')}</Form.Label>
                <Form.Control
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                />
            </Form.Group>
            <Form.Group as={Col} xxs="6" controlId="validationFormik02">
                <Form.Label>{t('account.LastName')}</Form.Label>
                <Form.Control
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
            </Form.Group>
        </Row>
        <Row className="mb-3">
            <Form.Group as={Col} xxs="10" controlId="validationFormik02">
                <Form.Label>{t('account.Email')}</Form.Label>
                <Form.Control
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value.toLowerCase())
                    }}

                />
            </Form.Group>
        </Row>
        <Row className="mb-3">


            <Form.Group as={Col} xxs="7" controlId="validationFormik031">
                <Form.Label className='div-row' >{t('account.Address')}</Form.Label>
                <Form.Control
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }}

                />
                <Form.Control
                    style={{ marginTop: 10 }}
                    type="text"
                    name="address2"
                    value={address2}
                    onChange={(e) => {
                        setAddress2(e.target.value)
                    }}

                />
            </Form.Group>

            <Form.Group as={Col} xxs="5" controlId="validationFormik03">
                <Form.Label>{t('account.City')}</Form.Label>
                <Form.Control
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => {
                        setCity(e.target.value)
                    }}
                />

            </Form.Group>
        </Row>
        <Row className="mb-3">

            <Form.Group xxs={5} as={Col} controlId="validationFormik04">
                <Form.Label>{t('account.State')}</Form.Label>
                <Form.Control
                    type="text"
                    name="state"
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value)
                    }}
                />
            </Form.Group>

            <Form.Group xxs={5} as={Col} controlId="validationFormik05">
                <Form.Label>{t('account.Zip')}</Form.Label>
                <Form.Control
                    type="text"
                    name="zip"
                    value={zip}
                    onChange={(e) => {
                        setZip(e.target.value)
                    }}
                />

            </Form.Group>
            <Col xxs={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <h4>{token_info?.data?.info?.country}</h4>
            </Col>
        </Row>


        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0px'
            }}
        >
            {
                api_result?.status === 'done' ?
                    <div />
                    :
                    <Button
                        onClick={onCancel}
                        variant="dark-outline"
                    >
                        Cancel
                    </Button>
            }

            <Button
                style={{ width: 120 }}
                disabled={api_result?.status === 'saving'}
                onClick={() => {
                    dispatch({
                        type: 'ACCOUNT_UPDATE',
                        payload: {
                            token,
                            body: {
                                firstName,
                                lastName,
                                email,
                                address,
                                address2,
                                city,
                                state,
                                zip
                            }
                        }
                    });
                }}
            >
                {api_result?.status === 'saving' ?
                    <Spinner animation="border" size="sm" />
                    :
                    t("common.save")
                }
            </Button>


        </div>
        {
            api_result?.status === 'done' &&

            <Alert
                variant={'success'}
                className="div-row-space-between"
            >

                Account Updated

                <Button
                    onClick={onCancel}
                    variant="dark-outline"
                >
                    OK
                </Button>
            </Alert>
        }
    </>

}

const AccountDisplay = ({
    token
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        token_info,
    } = dataReducer;

    if (!token_info) return null;

    return <>
        <Row>
            <Col>
                <h5>
                    {token_info?.data?.account?.firstName || ""} {token_info?.data?.account?.lastName || ""}
                </h5>
            </Col>
        </Row>

        {token_info?.data?.requests?.length > 0 &&
            <Alert
                variant='success'
            >
                {t('account.youHaveActiveRequests')}
                &nbsp;
                <Button
                    variant="secondary"
                    size='sm'
                    onClick={() => {
                        navigate(`/account/${token}/quotes`)
                    }}
                >
                    {t('account.quotes')}
                </Button>
            </Alert>

        }


        {
            (token_info?.data?.account?.address || "") === "" &&
            <Alert
                variant={'warning'}
            >
                {t('account.noAddress')}
            </Alert>
        }
        <Row>
            <Col>
                {token_info?.data?.account?.address || ""}
                <div>
                    {token_info?.data?.account?.address2 || ""}
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                {token_info?.data?.account?.city || ""} {token_info?.data?.account?.state || ""}
                <div>
                    {token_info?.data?.account?.zip || ""} {token_info?.data?.info?.country}
                </div>
            </Col>
        </Row>



    </>

}

const FirstTimeUser = () => {
    //const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);

    const {
        token_info,
    } = dataReducer;
    if (!token_info) return null;

    if (!token_info) return null;

    return <Container
        style={{ paddingTop: 20 }}
    >
        <Alert variant="success"  >

            <p>
                <Trans i18nKey="account.registration_success_text" />
            </p>
        </Alert>


    </Container>
}

const Account = (
    {
        firstTime
    }
) => {
    const routeParams = useParams();
    const {
        token
    } = routeParams;
    const { t } = useTranslation();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        token_info,
    } = dataReducer;


    const [edit, setEdit] = React.useState(false);

    return <Container
        style={{
            padding: 0,

        }}
    >
        <Header />

        <Container

            style={{
                padding: 0,
                maxWidth: 650,
            }}
        >
            {token_info && !token_info?.success ?
                <div
                    className='div-col'
                >
                    <Alert
                        className='alert-messages'

                    >
                        <p>
                            {t(`account.${token_info.msg}`)}

                        </p>
                        <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

                    </Alert>
                </div>
                :
                !!token_info && !!token_info?.data?.info?.account_created ?
                    <>
                        <AccountNav
                            activeKey="main"
                        />
                        <Container>
                            {firstTime &&

                                <FirstTimeUser />

                            }
                            {
                                edit ?
                                    <Container
                                        style={{
                                            maxWidth: 450,
                                            margin: '0 auto',
                                        }}
                                    >
                                        <AccountDetails
                                            token={token}
                                            onCancel={() => setEdit(false)}
                                        />

                                    </Container>
                                    :
                                    <Container
                                        style={{
                                            maxWidth: 350,
                                            margin: '0 auto',
                                            padding: 0
                                        }}
                                    >
                                        <AccountDisplay
                                            token={token}
                                        />
                                        <Button
                                            size='sm'
                                            style={{
                                                width: 120,
                                            }}
                                            onClick={() => setEdit(true)}
                                        >Update</Button>
                                    </Container>
                            }


                        </Container>
                    </>
                    :
                    <div className='div-col-margin-20'>
                        <Spinner animation="border" variant="warning" size="lg" />
                    </div>
            }

        </Container>
    </Container>



}
export default Account;