
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from './api';

function* fn_tokenInfoFetch(action) {
    try {
        //let phone = yield select(getPhone);

        yield put({ type: "UPDATING_TOKEN", updating_token: true });
        const data = yield call(api.tokenInfoFetch, action);
        yield put({ type: "UPDATING_TOKEN", updating_token: false });

        yield put({ type: "TOKEN_INFO_SET", data });
        if (!data?.data?.info?.account_created && action.navigate) {

            if (data?.data?.account?.zip === null) {
                action.navigate(`/nozip/${action.payload.token}?back=${window.location.pathname}`)
            } else {

                if (data.msg === 'expired_token') {
                    action.navigate(`/expired/${action.payload.token}?back=${window.location.pathname}`)
                } else {
                    action.navigate(`/expired/${action.payload.token}?msg=finish_signup`)
                }
            }
        }



    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}

function* fn_signUp(action) {
    try {
        //let phone = yield select(getPhone);

        yield put({ type: "API_RESULT_SET", data: { status: 'saving' } });
        const data = yield call(api.signUp, action);
        yield put({ type: "API_RESULT_SET", data: { ...data, status: 'done' } });
        yield put({ type: "USER_SET", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}


function* fn_logout(action) {
    try {

        const data = yield call(api.logout, action);
        yield put({ type: "TOKEN_INFO_CLEAR", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}

function* fn_quoteRequestSubmit(action) {
    try {

        const data = yield call(api.quote_request, action);
        //yield put({ type: "TOKEN_INFO_CLEAR", data });
        console.warn("quote request", data);

    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}


function* fn_accountUpdate(action) {
    try {
        //let phone = yield select(getPhone);

        yield put({ type: "API_RESULT_SET", data: { status: 'saving' } });
        const data = yield call(api.accountUpdate, action);
        console.log(data);
        yield put({ type: "API_RESULT_SET", data: { ...data, status: 'done' } });
        yield put({ type: "USER_UPDATE", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}

export default function* rootSaga() {

    yield takeLatest('LOGOUT', fn_logout);
    yield takeLatest('TOKEN_INFO_FETCH', fn_tokenInfoFetch);
    yield takeLatest('USER_SIGN_UP', fn_signUp);
    yield takeLatest('ACCOUNT_UPDATE', fn_accountUpdate);

    yield takeLatest('QUOTE_REQUEST_SUBMIT', fn_quoteRequestSubmit);

}

