import React, { Suspense } from 'react';
import moment from 'moment-timezone';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';

import Header from '@Components/HeaderAccount';
import { IoChevronBack, IoLogoWhatsapp, IoHelpCircle } from "react-icons/io5";

import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import 'moment/locale/pt';
import 'moment/locale/es';

const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const Message = ({
    token
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <>

        <p className='text-center'>{t('account.support_text')}</p>
        <a href={`https://wa.me/18634519550?text=${t('common.support')}`} target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>
        <Container
            className='mt-3 d-flex justify-content-center'

        >
            <Button
                onClick={() => navigate(`/account/${token}/quote`)}
            >{t('account.quote_request_form')}</Button>
        </Container>

    </>
}


const ItemArray = ({
    partsArray
}) => {

    return <div>
        {
            JSON.parse(partsArray).map((e, index) => {

                return <div
                    key={
                        index
                    }
                >
                    {e.brand} {e.model} {e.year}

                </div>
            })
        }
    </div>


}

const AccountSupport = () => {
    const { t } = useTranslation();

    moment.locale(localStorage.getItem("lng") || "en")
    const routeParams = useParams();
    const {
        token
    } = routeParams;
    const dataReducer = useSelector((state) => state.dataReducer);

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [section, setSection] = React.useState('tickets');

    const {
        token_info,
    } = dataReducer;


    return <Suspense><Container
        style={{
            maxWidth: 650,
            padding: 0
        }}
    >
        <Header />

        {token_info && !token_info?.success ?
            <div
                className='div-col'
            >
                <Alert
                    className='alert-messages'

                >
                    <p>
                        {t(`account.${token_info.msg}`)}

                    </p>
                    <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

                </Alert>
            </div>

            :
            <>


                <Container

                    style={{
                        padding: '10px 0px',
                        maxWidth: 650,
                    }}
                >



                    {
                        !token_info ?
                            <div className='div-col-margin-20'>
                                <Spinner animation="border" variant="warning" size="lg" />
                            </div>


                            :

                            <>

                                <Nav className="justify-content-center" variant="tabs" activeKey={section}  >
                                    <Nav.Item>
                                        <LinkContainer to={`/account/${token}/`} >
                                            <Nav.Link eventKey="main" ><IoChevronBack /></Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item >
                                        <Nav.Link onClick={() => setSection('tickets')} eventKey="tickets" >{t('account.tickets')}</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link onClick={() => setSection('quotes')} eventKey="quotes" >{t('account.quotes')}</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <div className='div-col' style={{ padding: 10 }}>
                                    <Button size='sm' variant='outline-secondary' onClick={handleShow}>
                                        <IoHelpCircle size={25} />
                                        {t('common.support')}
                                    </Button>
                                </div>



                                {section === 'tickets' &&
                                    (
                                        token_info?.data?.tickets?.length > 0 ?
                                            <Table striped small >

                                                <tbody>
                                                    {
                                                        token_info?.data?.tickets.map((ticket, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <small>{titleCase(moment(ticket.dt).format("dddd, MMMM DD HH:mm"))}</small>
                                                                    <br />
                                                                    {ticket.message}
                                                                </td>
                                                            </tr>

                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                            :
                                            <Alert
                                                style={{
                                                    margin: 'auto'
                                                }}
                                                className='alert-messages'

                                            >
                                                <Message
                                                    token={token}
                                                />
                                            </Alert>
                                    )
                                }


                                {section === 'quotes' &&

                                    (
                                        token_info?.data?.requests?.length > 0 ?
                                            <Table striped small >

                                                <tbody>
                                                    {
                                                        token_info?.data?.requests.map((request, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <small>{titleCase(moment(request.dt).format("dddd, MMMM DD HH:mm"))}</small>
                                                                    <br />

                                                                    {request.parts_array !== null
                                                                        ?

                                                                        <ItemArray partsArray={request.parts_array} />
                                                                        :
                                                                        <>
                                                                            {request.part_number}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>

                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                            :
                                            <Alert
                                                style={{
                                                    margin: 'auto'
                                                }}
                                                className='alert-messages'

                                            >
                                                <Message
                                                    token={token}
                                                />
                                            </Alert>
                                    )
                                }


                            </>
                    }

                </Container>
            </>}

        <Modal size="sm" show={show} centered onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <img
                    src="/alaniO.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Alani360 logo"
                />
                <Modal.Title>ALANI Garage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='div-col'>
                    <Message token={token} />
                </div>
            </Modal.Body>



        </Modal>
    </Container>
    </Suspense >

}
export default AccountSupport;