import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useParams, } from 'react-router-dom';

import { LinkContainer } from 'react-router-bootstrap';


import { IoLogoWhatsapp, IoPersonCircleSharp } from "react-icons/io5";
const Header = () => {
    const { t, i18n } = useTranslation();
    const [lng, setLng] = React.useState(localStorage.getItem("lng") || "en");
    const [expanded, setExpanded] = React.useState(false);
    const routeParams = useParams();
    const {
        token
    } = routeParams;


    React.useEffect(() => {
        let _lng =
            (
                window.location.host === 'alanigaragebrasil.com.br'
                ||
                window.location.host === 'alanigarage.com.br'
                ||
                window.location.host === 'www.alanigarage.com.br'
                ||
                window.location.host === 'www.alanigaragebrasil.com.br'

            ) ? "pt"
                : window.location.host === 'alanigarage.com.mx' ? "es" : lng;

        localStorage.setItem("lng", _lng);

        i18n.changeLanguage(_lng);
        setExpanded(false);
    }, [lng]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>

        <Navbar bg="light"
            expand="xxl"
            fixed="top" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            <Container

            >
                <LinkContainer to={`/home/${token || ""}`}>
                    <Navbar.Brand href="#home" className='div-row'>
                        <img
                            src="/alaniO.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Alani360 logo"
                        />
                        &nbsp;
                        <h4 style={{ margin: 0, fontWeight: 'normal' }}>ALANI Garage</h4>
                        &nbsp;
                        {lng === 'pt' &&
                            <img
                                src="/br.svg"
                                width="25"
                                alt="Alani360 logo"
                            />
                        }
                    </Navbar.Brand>
                </LinkContainer>
                &nbsp;&nbsp;&nbsp;
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav"  >
                    <Nav className="me-auto">

                        {!!token ?
                            <LinkContainer to={`/account/${token || ""}`} >
                                <Nav.Link href="#" className='div-row'><IoPersonCircleSharp color="orange" />&nbsp;{t('common.account')}</Nav.Link>
                            </LinkContainer>
                            :
                            <LinkContainer to={`/sign-up/`} >
                                <Nav.Link href="#">{t('home.getStarted')}</Nav.Link>
                            </LinkContainer>
                        }
                        <Nav.Link href="https://wa.me/18634519550?text=ALANI" target="_blank" rel="noreferrer" style={{ textShadow: '2px' }} className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp</Nav.Link>

                        <LinkContainer to={`/alani-garage/${token || ""}`} >
                            <Nav.Link href="#">{t('common.what is alani garage')}</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to={`/auto-brands/${token || ""}`}  >
                            <Nav.Link href="#">{t('common.auto_brands')}</Nav.Link>
                        </LinkContainer>


                        {
                            window.location.host !== 'alanigaragebrasil.com.br'
                            && window.location.host !== 'alanigarage.com.mx'
                            && window.location.host !== 'alanigarage.com.br'
                            && window.location.host !== 'www.alanigarage.com.br'
                            && window.location.host !== 'www.alanigaragebrasil.com.br'

                            &&
                            <NavDropdown title={lng} id="basic-nav-dropdown" style={{ maxWidth: 100 }}>
                                <NavDropdown.Item href="#" onClick={() => setLng('es')} >Español</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => setLng('pt')}>
                                    Português
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => setLng('en')}>English</NavDropdown.Item>

                            </NavDropdown>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container style={{ height: 60 }}></Container>

    </>


}

export default Header;