import { combineReducers } from 'redux';

let dataState = {

    token_info: null,
    updating_token: false,
}

const dataReducer = (state = dataState, action) => {
    switch (action.type) {

        case 'ACCOUNT_FIELDS_UPDATE':
            return {
                ...state,
                account_fields_uddated: action.data
            }

        case 'UPDATING_TOKEN':
            return {
                ...state,
                updating_token: action.updating_token
            }

        case 'QUOTE_PART_UPDATE':
            return {
                ...state,
                quote_part_updated: action.quote_part_updated
            }

        case 'API_RESULT_CLEAR':
            return {
                ...state,
                api_result: null
            }
        case 'API_RESULT_SET':
            return {
                ...state,
                api_result: action.data
            }

        case 'TOKEN_INFO_SET':
            return {
                ...state,
                token_info: action.data
            }
        case 'USER_UPDATE':

            return {
                ...state,
                token_info: {
                    ...state.token_info,
                    data: {
                        ...state.token_info.data,
                        account: action.data.data.user
                    }
                }
            }
        case 'TOKEN_INFO_CLEAR':
            return {
                ...state,
                token_info: null
            }

        case 'MENU_SET':
            return {
                ...state,
                menu: action.data.success ? action.data.menu : null,
                categories: action.data.success ? action.data.categories : null,
                restaurant: action.data.success ? action.data.restaurant : null
            }
        default:
            return state;
    }

}


const rootReducer = combineReducers({
    dataReducer,
})


export default rootReducer;