import React from 'react';
import Container from 'react-bootstrap/Container';

import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from '@Components/HeaderAccount';
import Spinner from 'react-bootstrap/Spinner';

import Card from 'react-bootstrap/Card';

import { IoLogoWhatsapp, } from "react-icons/io5";
import { api } from '../api'



const PaymentComponent = ({
    token,
    quote_id
}) => {
    //const { t } = useTranslation();
    //const dataReducer = useSelector((state) => state.dataReducer);
    //const dispatch = useDispatch();
    //const navigate = useNavigate();


    const [fetching, setFetching] = React.useState(false);
    const [payment, setPayment] = React.useState({});




    const fnFetchPayment = async () => {
        setFetching(true);
        let res = await api.payment_fetch({
            payload: {
                token,
                quote_id
            }
        })
        setFetching(false);
        setPayment(res);

    }

    React.useLayoutEffect(() => {
        if (fetching) return;
        fnFetchPayment();

    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    if (fetching) return <Container
        className='div-col'
        style={{
            marginTop: 50
        }}
    >
        <Spinner size='lg' />

    </Container>


    if (!payment?.success) {
        return <Container
            className='div-col'
            style={{
                marginTop: 50
            }}
        >

            <center>
                <p>
                    <Trans i18nKey="account.no_quote" />
                </p>


                <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" size={14} style={{ textShadow: '2px', color: 'blue' }}   ><IoLogoWhatsapp />&nbsp;+18634519550</a>
            </center>
        </Container>
    }
    return <>
        <Container>
            <Card
                style={{ margin: '20px auto', marginTop: 20, maxWidth: '25rem' }}
                size='sm'
            >
                <Card.Body>

                    <Card.Title>
                        Payment
                    </Card.Title>

                    <Card.Text>
                        {JSON.stringify(payment)}
                    </Card.Text>


                </Card.Body>



            </Card>
        </Container>

    </>
}
const QuotePayment = (

) => {
    const routeParams = useParams();
    const {
        token,
        quote_id
    } = routeParams;

    const dataReducer = useSelector((state) => state.dataReducer);
    const { token_info, } = dataReducer; // eslint-disable-line



    return <Container
        style={{
            padding: 0
        }}
    >
        <Header />



        <PaymentComponent
            token={token}
            quote_id={quote_id}
        />
    </Container>



}
export default QuotePayment;