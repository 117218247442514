
import Spinner from 'react-bootstrap/Spinner';


const Loading = () => <div className="div-col" style={{ paddingTop: 20 }}>

    <div className="div-col" style={{ margin: 20 }}>

        <div style={{
            textAlign: 'center',
        }}>
            Loading &nbsp; <Spinner animation="border" size="sm" />
        </div>
    </div>
</div>

export default Loading;