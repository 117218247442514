import React from 'react';

import { useParams, } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
//import { useSelector } from 'react-redux';
import Header from '@Components/Header';
//import Spinner from 'react-bootstrap/Spinner';
//import Modal from 'react-bootstrap/Modal';

//import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
    Button,
    Card,
    Spinner,
    Container
} from 'react-bootstrap';

import { IoLogoWhatsapp, IoCheckmarkCircle } from "react-icons/io5";
import { whatsapp_api } from '../api'
//import Address from '@Components/Address';

//import moment, { max } from 'moment-timezone';
//import 'moment/locale/pt';
//import 'moment/locale/es';

/*
const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}
const fn_formatPrice = (price, currency, locale) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency

    }).format(price);

    return price_formatted;

};
*/
const QuoteSummary = (

) => {
    const { t } = useTranslation();
    const routeParams = useParams();
    const {
        code
    } = routeParams;


    const [res, setRes] = React.useState(null);

    const [fetching, setFetching] = React.useState(false);


    const fnFetchQuote = async () => {
        setFetching(true);
        let res = await whatsapp_api.quote_fetch({
            payload: {
                code,
            }
        });
        console.log(res);
        setRes(res);
        setFetching(false);

    }


    React.useEffect(() => {

        fnFetchQuote();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <Container
        style={{
            padding: 0,
            maxWidth: 400,
            margin: '0 auto'
        }}
    >
        <Header />

        {fetching ?
            <Container
                className='d-flex  justify-content-center align-items-center mt-5 flex-column'
            >
                <Spinner role="status" />
                <br />
                Loading...
            </Container>

            :
            <Container >
                <Card>
                    <Card.Header>
                        <Card.Title>
                            {t('account.quote')} {res?.data?.quote?.quote_request_id}
                        </Card.Title>
                    </Card.Header>

                    {
                        res?.data?.files?.map((image, index) => {
                            return <Card.Img key={index} variant="top" src={image.file_url} />
                        })
                    }

                    <Card.Body>
                        <Card.Text>

                            {res?.data?.quote?.quote_request_brand} {res?.data?.quote?.quote_request_model}


                            <div>
                                {res?.data?.quote?.quote_request_description}
                            </div>

                            <div>{res?.data?.quote?.quote_request_part_number_confirmed || res?.data?.quote?.quote_request_part_number}</div>
                            <div>{res?.data?.quote?.quote_request_notes}</div>

                            {res?.data?.quote?.rfq_status !== 'pending' ?


                                <div
                                >
                                    <b>{res?.data?.quote?.quoted_amount_total} USD</b>
                                </div>
                                :
                                <div
                                    className='mt-2'
                                >
                                    <Trans i18nKey="common.Pending_Explained" />
                                </div>
                            }


                        </Card.Text>
                        <Card.Text>
                            <b>{t({
                                "pending": "common.Pending",
                                "waiting_for_response": "common.Waiting for Response",
                                'approved': 'common.Pending Payment',
                                'paid': 'common.Paid',

                            }[res?.data?.quote?.rfq_status]
                            )}</b>

                            <div>
                                {res?.data?.quote?.payment_date || res?.data?.quote?.approval_date}
                            </div>
                            {res?.data?.quote?.payment_url && res?.data?.quote?.rfq_status === 'approved' &&
                                <a href={res?.data?.quote?.payment_url} target='_blank' rel="noreferrer">
                                    {t('common.Pay Here')}
                                </a>
                            }
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer
                        className='d-flex justify-content-between align-items-center'
                    >
                        <Button
                            onClick={() => window.open(`https://wa.me/18634519550?text=rfq ${res?.data?.quote?.quote_request_id}`, '_blank')}
                            variant="primary"
                            block
                        >
                            <IoLogoWhatsapp /> Whatsapp
                        </Button>

                        {res?.data?.quote?.rfq_status === 'waiting_for_response' &&
                            <div>
                                <Button
                                    variant="success"
                                    block
                                    onClick={() => window.open(`https://wa.me/18634519550?text=rfq ${res?.data?.quote?.quote_request_id}`, '_blank')}

                                >
                                    <IoCheckmarkCircle /> Accept
                                </Button>
                            </div>

                        }



                    </Card.Footer>
                </Card>

            </Container>
        }


    </Container >



}
export default QuoteSummary;