import React, { Suspense } from 'react';
import moment from 'moment-timezone';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';

import Header from '@Components/HeaderAccount';
import { IoLogoWhatsapp } from "react-icons/io5";

import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import AccountNav from '@Components/AccountNav';

import 'moment/locale/pt';
import 'moment/locale/es';

const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

const Message = ({
    token
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <>

        <p className='text-center'>{t('account.request_quote')}</p>
        <a href={`https://wa.me/18634519550?text=${t('common.support')}`} target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

        <Container
            className='mt-3'
        >
            <Button
                onClick={() => navigate(`/account/${token}/quote`)}
            >{t('account.quote_request_form')}</Button>
        </Container>

    </>
}



const AccountSupport = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    moment.locale(localStorage.getItem("lng") || "en")
    const routeParams = useParams();
    const {
        token
    } = routeParams;
    const dataReducer = useSelector((state) => state.dataReducer);

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);


    const {
        token_info,
    } = dataReducer;


    return <Suspense><Container
        style={{
            maxWidth: 650,
            padding: 0
        }}
    >
        <Header />

        {token_info && !token_info?.success ?
            <div
                className='div-col'
            >
                <Alert
                    className='alert-messages'

                >
                    <p>
                        {t(`account.${token_info.msg}`)}

                    </p>
                    <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp +18634519550</a>

                </Alert>
            </div>

            :
            <>


                <Container

                    style={{
                        padding: 0,
                        maxWidth: 650,
                    }}
                >


                    <AccountNav />


                    {
                        !token_info ?
                            <div className='div-col-margin-20'>
                                <Spinner animation="border" variant="warning" size="lg" />
                            </div>


                            :

                            <>







                                {


                                    token_info?.data?.requests?.length > 0 ?

                                        <Container>
                                            <Container
                                                className='div-col pt-1 pb-3'
                                                style={{ minWidth: 300, }}
                                            >
                                                <Button
                                                    onClick={() => navigate(`/account/${token}/quote`)}
                                                >{t('account.quote_request_form')}</Button>
                                            </Container>
                                            <Table striped small >

                                                <tbody>
                                                    {
                                                        token_info?.data?.requests.map((request, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <small>{titleCase(moment(request.dt).format("dddd, MMMM DD HH:mm"))}</small>
                                                                    <br />


                                                                    {request.brand} {request.model}
                                                                    <div>
                                                                        {request.part_number}
                                                                    </div>
                                                                    <div>

                                                                        {request.notes}
                                                                    </div>

                                                                </td>
                                                            </tr>

                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </Container>
                                        :
                                        <Alert
                                            style={{
                                                margin: 'auto'
                                            }}
                                            className='alert-messages'
                                            variant='success'
                                        >
                                            <Message
                                                token={token}
                                            />
                                        </Alert>

                                }


                            </>
                    }

                </Container>
            </>}

        <Modal size="sm" show={show} centered onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <img
                    src="/alaniO.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Alani360 logo"
                />
                <Modal.Title>ALANI Garage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='div-col'>
                    <Message token={token} />
                </div>
            </Modal.Body>



        </Modal>
    </Container>
    </Suspense >

}
export default AccountSupport;