let root = process.env.REACT_APP_API;
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJlc0lkIjoxLCJpYXQiOjE2NzM3MTQyMDB9.pATGIYj4UNZ_JIfMElLFyapS-rbWupMX4Mhsz8YRoLU";
const Authorization = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJlc0lkIjozLCJpYXQiOjE2NzQ3NTk3NzJ9.cExPjXeVt-Pf_AWWY6VrxKkpEfU4hPGSvmJslDga6Cs";
const api_key = process.env.REACT_APP_API_KEY;

const api = {

    payment_fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/account/${args.payload.token}/quote/${args.payload.quote_id}/payment`;


            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", Authorization);

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    quote_approve: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/account/${args.payload.token}/quote/${args.payload.quote_id}`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", Authorization);


            fetch(url, {
                headers,
                method: 'PUT',
                body: JSON.stringify({ shipping_address: args.payload.shipping_address })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },
    quote_fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/account/${args.payload.token}/quote/${args.payload.quote_id}`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", Authorization);

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    quote_request: (args) => {
        return new Promise((resolve, reject) => {


            let url = `${root}/account/${args.payload.token}/quote-request`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", "");

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },



    logout: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/registration/${args.payload.token}`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);
            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    accountUpdate: (args) => {
        return new Promise((resolve, reject) => {
            let url = `${root}/account/${args.payload.token}/info`;

            var headers = new Headers();
            headers.append("x-api-key", api_key);
            fetch(url, {
                headers,
                method: 'PUT',
                body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {

                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    signUp: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/registration/${args.payload.token}`;

            var headers = new Headers();
            headers.append("x-api-key", api_key);
            fetch(url, {
                headers,
                method: 'PUT',
                body: JSON.stringify(args.payload.body)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    tokenInfoFetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/registration/${args.payload.token}`;

            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

}

const car_api = {

    fetch: (args) => {
        return new Promise((resolve, reject) => {
            let url = `${root}/account/${args.token}/car-api/${args.api}?year=${args.year}`;

            if (args.api === 'models' || args.api === 'trims' || args.api === 'engines') {
                url += `&make_id=${args.make_id}`;
            }

            if (args.api === 'trims' || args.api === 'engines') {
                url += `&make_model_id=${args.make_model_id}`;
            }

            if (args.api === 'engines') {
                url += `&make_model_trim_id=${args.make_model_trim_id}`;
            }


            url += `&verbose=${args.verbose || 'no'}`;


            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", Authorization);



            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },
}

const whatsapp_api = {
    quote_fetch: (args) => {
        return new Promise((resolve, reject) => {

            let url = `${root}/wap/alani-garage/${args.payload.code}`;
            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", Authorization);

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },
}

export {
    api,
    car_api,
    whatsapp_api
};