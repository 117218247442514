import React, { Suspense } from 'react';
//import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';


import Header from '@Components/HeaderAccount';
import Navigator from '@Components/AccountNav';

const AccountPaymentInformation = () => {

    return <Suspense><Container
        style={{
            padding: 0
        }}
    >
        <Header />



        <Container

            style={{
                maxWidth: 650,
                padding: 0,
                margin: 'auto'
            }}
        >
            <Navigator
                activeKey="payment"
            />
            <Alert variant="warning" className='alert-messages'
                style={{
                    margin: 'auto'
                }}
            >
                <Alert.Heading>PAYMENT</Alert.Heading>
                <p>PAYMENT API</p>

            </Alert>
        </Container>




    </Container>
    </Suspense >

}
export default AccountPaymentInformation;