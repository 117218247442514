import React from 'react';
//import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from '@Components/HeaderAccount';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import Loading from '@Components/Loading';


const QuoteRequestSuccess = (
) => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const {
        token,
    } = routeParams;
    const { t } = useTranslation();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        updating_token
    } = dataReducer;



    //if (updating_token) return <Loading />;
    return <Container
        style={{
            padding: 0,

        }}
    >
        <Header />

        {updating_token
            ?
            <Loading />
            :


            <Container
                className='mt-3'
            >
                <Alert
                    variant="success"
                    style={{
                        maxWidth: 600,
                        margin: 'auto'
                    }}
                >

                    <p style={{
                        textAlign: 'center'
                    }}>
                        <Trans i18nKey="account.multiple_part_quote_request_success" />

                    </p>


                    <div

                        className='mt-3 d-flex justify-content-center'

                    >
                        <Button
                            onClick={() => navigate(`/account/${token}/quotes`)}
                        >{t('account.support')}</Button>
                    </div>
                </Alert>

            </Container>
        }


    </Container >



}
export default QuoteRequestSuccess;