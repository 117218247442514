import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './app/store'

import Home from '@Screens/Home';
import AlaniGarage from '@Screens/AlaniGarage';
import AutoBrands from '@Screens/AutoBrands';



import Account from '@Screens/Account';
import AccountPackages from '@Screens/AccountPackages';
import AccountQuotes from '@Screens/AccountQuotes';
import AccountTickets from '@Screens/AccountTickets';
import AutopartQuote from '@Screens/AutopartQuote';
import QuoteRequestForm from '@Screens/QuoteRequestForm';
import QuoteRequestSuccess from '@Screens/QuoteRequestSuccess';

import QuotePayment from '@Screens/QuotePayment';
import QuotePaymentRedirect from '@Screens/PaymentRedirect';

import QuoteSummary from './app/screens/QuoteSummary';

import Expired from '@Screens/Expired'

import NoZip from '@Screens/NoZip'



//todo: remove these2
import AccountPaymentInformation from '@Screens/AccountPaymentInformation';
import AccountSupport from '@Screens/AccountSupport';



const SignUp = lazy(() => import('@Screens/SignUp'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Suspense fallback={<div style={{ padding: 20, textAlign: 'center' }}>Loading...</div>}>
          <BrowserRouter>
            <Routes>

              <Route path='/'
                element={<Home />}
              />


              <Route path='/home/:token'
                element={<Home />}
              />
              <Route path='/home'
                element={<Home />}
              />


              <Route path='/alani-garage/:token'
                element={<AlaniGarage />}
              />
              <Route path='/alani-garage'
                element={<AlaniGarage />}
              />

              <Route path='/auto-brands/:token'
                element={<AutoBrands />}
              />
              <Route path='/auto-brands'
                element={<AutoBrands />}
              />


              <Route path='/sign-up/'
                element={<SignUp />}
              />
              <Route path='/sign-up/:token'
                element={<SignUp />}
              />


              <Route path='/account/'
                element={<Account />}
              />
              <Route path='/account/:token'
                element={<Account />}
              />

              <Route path='/q/:quote_id'
                element={<AutopartQuote />}
              />
              <Route path='/a/:token/q/:quote_id'
                element={<AutopartQuote />}
              />

              <Route path='/a/:token/q/:quote_id/pay'
                element={<QuotePayment />}
              />
              <Route path='/p/:quote_id'
                element={<QuotePaymentRedirect />}
              />
              <Route path='/f1/:token'
                element={<Account
                  firstTime={true}
                />}
              />
              <Route path='/account/:token/packages'
                element={<AccountPackages />}
              />
              <Route path='/account/:token/quotes'
                element={<AccountQuotes />}
              />

              <Route path='/account/:token/tickets'
                element={<AccountTickets />}
              />

              <Route path='/wq/:code'
                element={<QuoteSummary />}
              />


              <Route path='/account/:token/payment-information'
                element={<AccountPaymentInformation />}
              />

              <Route path='/account/:token/support'
                element={<AccountSupport />}
              />



              <Route path='/account/:token/quote'
                element={<QuoteRequestForm />}
              />
              <Route path='/account/:token/quote/success'
                element={<QuoteRequestSuccess />}
              />

              <Route path='/expired/:token'
                element={<Expired />}
              />

              <Route path='/nozip/:token'
                element={<NoZip />}
              />
              <Route path='/:token'
                element={<Account />}
              />

              <Route path='/:token/quote'
                element={<QuoteRequestForm />}
              />

              <Route path='/:token/support'
                element={<AccountSupport />}
              />

              <Route
                path="*"
                element={<App />}
              />

            </Routes>
          </BrowserRouter>
        </Suspense>

      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
