import React from 'react';
//import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Header from '@Components/HeaderAccount';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';

import Loading from '@Components/Loading';

import { IoCamera } from "react-icons/io5";

//TODO: remove from pacakge.json => import { Typeahead } from 'react-bootstrap-typeahead';
import Spinner from 'react-bootstrap/Spinner'

import {
    car_api
} from '../api';


const DATA_API_INITAL = {
    year: '2020',
    brands: [],
    makes: [],
    models: [],
    trims: [],
    engines: []
}

const fnYearsToDateArray = () => {
    return [2020];
    /* const currentYear = new Date().getFullYear();
     const years = [];
     for (let i = 0; i < 20; i++) {
         years.push(currentYear - i);
     }
     return years;*/
}


const PartForm = ({
    part,
    token
}) => {



    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [year, setYear] = React.useState(part?.year || '0');
    const [brand, setBrand] = React.useState(part?.brand || '');

    const [brandId, setBrandId] = React.useState(part?.brandId || '');
    const [modelId, setModelId] = React.useState(part?.modelId || '');
    const [trimId, setTrimId] = React.useState(part?.trimId || '');
    const [engineId, setEngineId] = React.useState(part?.engineId || '');

    const [model, setModel] = React.useState(part?.model || '');
    const [trim, setTrim] = React.useState(part?.trim || '');
    const [engine, setEngine] = React.useState(part?.engine || '');
    const [partDescription, setPartDescription] = React.useState(part?.partDescription || '');
    const [partName, setPartName] = React.useState(part?.partName || '');

    const [VIN, setVIN] = React.useState(part?.VIN || '');


    const [data_api, set_data_api] = React.useState(DATA_API_INITAL);

    const [fetching, setFetching] = React.useState(false);
    const [fetchingData, setFetchingData] = React.useState(false);

    React.useEffect(() => {

        if (part.engineId) {
            //fetch vehicle data from car api
            setFetching(true);
            car_api.fetch({
                token,
                api: "makes",
                year: part.year,
            }).then((res) => {

                let _brands = res.data.data.map(e => ({ label: e.name, value: e.id }));

                car_api.fetch({
                    token,
                    api: "models",
                    year: part.year,
                    make_id: part.brandId,
                }).then((res) => {
                    let _models = res.data.data.map(e => ({ label: e.name, value: e.id }));
                    car_api.fetch({
                        token,
                        api: "trims",
                        year: part.year,
                        make_id: part.brandId,
                        make_model_id: part.modelId,
                    }).then((res) => {
                        let _trims = res.data.data.map(e => ({ label: e.name, value: e.id }));
                        car_api.fetch({
                            token,
                            api: "engines",
                            year: part.year,
                            make_id: part.brandId,
                            make_model_id: part.modelId,
                            make_model_trim_id: part.trimId,
                        }).then((res) => {
                            let _engines = res.data.data.map(e => ({ label: e ? `${e.engine_type || ""} ${e.cylinders || ""} ${e.size || ""} ${e.drive_type || ""}` : '?', value: e.id }));

                            set_data_api({
                                year: part.year,
                                brands: _brands,
                                models: _models,
                                trims: _trims,
                                engines: _engines
                            });
                            setFetching(false);
                        });//get engines
                    });//get trims
                });//get models

            });//get brands


        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //update part every time a field changes
    React.useEffect(() => {
        if (!part) return;
        dispatch({
            type: 'QUOTE_PART_UPDATE',
            quote_part_updated: {
                partName,
                year,
                brand,
                model,
                trim,
                engine,
                partDescription,
                VIN,


                brandId,
                modelId,
                trimId,
                engineId
            }
        });

    }, [partName, year, brand, model, trim, engine, partDescription, VIN]); // eslint-disable-line react-hooks/exhaustive-deps

    const fnUpdateCarData = ({
        year,
        api,
        make_id,
        make_model_id,
        make_model_trim_id,
        callback
    }) => {
        setFetchingData(api);
        car_api.fetch({
            token,
            api,
            year,
            make_id,
            make_model_id,
            make_model_trim_id

        }).then((res) => {
            setFetchingData(false);

            let _brands = [], _models = [], _trims = [], _engines = [];

            if (api === 'makes') {
                _brands = res.data.data.map(e => ({ label: e.name, value: e.id }));
            } else if (api === 'models') {
                _brands = data_api.brands;
                _models = res.data.data.map(e => ({ label: e.name, value: e.id }));
            } else if (api === 'trims') {
                _brands = data_api.brands;
                _models = data_api.models;
                _trims = res.data.data.map(e => ({ label: e.name, value: e.id }));
            } else if (api === 'engines') {
                _brands = data_api.brands;
                _models = data_api.models;
                _trims = data_api.trims;
                _engines = res.data.data.map(e => ({ label: e ? `${e.engine_type || ""} ${e.cylinders || ""} ${e.size || ""} ${e.drive_type || ""}` : '?', value: e.id }));
            }

            set_data_api({
                year,
                brands: _brands,
                models: _models,
                trims: _trims,
                engines: _engines
            });

            /*  set_data_api({
                  year,
                  brands: api === 'makes' ? res.data.data.map(e => ({ label: e.name, value: e.id })) : data_api.brands,
                  models: api === 'models' ? res.data.data.map(e => ({ label: e.name, value: e.id })) : data_api.models,
                  trims: api === 'trims' ? res.data.data.map(e => ({ label: e.description, value: e.id })) : data_api.trims,
                  engines: api === 'engines' ? res.data.data.map(e => ({ label: e ? `${e.engine_type} ${e.cylinders} ${e.size} ${e.drive_type}` : '?', value: e.id })) : data_api.engines,
              });*/
            if (callback) callback();

        });
    }

    if (fetching) return <Container>
        <Loading />
    </Container>

    return <>
        <Container >

            <Row >
                <Col
                    xxs={6}
                >
                    <Form.Select
                        aria-label="Vehicle Year"

                        onChange={(e) => {
                            setYear(e.target.value);
                            set_data_api(DATA_API_INITAL);
                            //typeaheadRefBrand.current.clear();
                            //typeaheadRefModel.current.clear();
                            //typeaheadRefTrims.current.clear();
                            //typeaheadRefEngine.current.clear();
                            setBrandId(null);
                            setModelId(null);
                            setTrimId(null);
                            setEngineId(null);

                            set_data_api({
                                year: e.target.value,
                                brands: [],
                                models: [],
                                trims: [],
                                engines: []
                            });
                            fnUpdateCarData({
                                year: e.target.value,
                                api: 'makes'
                            });
                        }}
                        value={year}
                    >
                        <option key='0'>{t('common.vehicle_year')}</option>
                        {fnYearsToDateArray().map((year) => (
                            <option key={year}>{year}</option>
                        ))}

                    </Form.Select>
                </Col>
            </Row>
            <Row
                className='mt-1'
            >
                <Col>


                    <>
                        {data_api.brands.length === 0 ?

                            <div style={styles.lbl}>

                                Make
                                {fetchingData === 'makes' && <>&nbsp;<Spinner animation="grow" size="sm" /></>}

                            </div>

                            :

                            <Form.Select
                                aria-label="Vehicle Make"
                                onChange={(e) => {
                                    let _element = data_api.brands[e.target.value] || { label: 'Select', value: null }
                                    setBrand(_element.label);
                                    setBrandId(_element.value);

                                    setModelId(null);
                                    setTrimId(null);
                                    setEngineId(null);
                                    set_data_api({
                                        ...data_api,
                                        models: [],
                                        trims: [],
                                        engines: []
                                    });
                                    fnUpdateCarData({
                                        year,
                                        api: 'models',
                                        make_id: _element.value
                                    });
                                }}
                            >
                                <option key='0'>Select</option>
                                {
                                    data_api.brands.map((brand, index) => (
                                        <option key={brand.value} value={index}>{brand.label}</option>
                                    ))
                                }
                            </Form.Select>
                        }


                        {/*  
                                <Typeahead
                                    id="brand"
                                    ref={typeaheadRefBrand}
                                    onChange={(selected) => {
                                        setBrand(selected[0]?.label);
                                        setBrandId(selected[0]?.value);
                                        typeaheadRefModel.current.clear();
                                        typeaheadRefTrims.current.clear();
                                        typeaheadRefEngine.current.clear();
                                        setModelId(null);
                                        setTrimId(null);
                                        setEngineId(null);
                                        fnUpdateCarData({
                                            year,
                                            api: 'models',
                                            make_id: selected[0]?.value
                                        });
                                    }}
                                    options={data_api.brands}
                                    placeholder={t('common.brand')}
                                    selected={data_api.brands.filter(e => e.value === brandId)}
                                />
                                */}
                    </>


                </Col>
                <Col

                >
                    {
                        data_api.models.length === 0 ?
                            <div style={styles.lbl}>

                                Model
                                {fetchingData === 'models' && <>&nbsp;<Spinner animation="grow" size="sm" /></>}
                            </div>
                            :

                            <Form.Select
                                aria-label="Vehicle Model"
                                onChange={(e) => {
                                    let _element = data_api.models[e.target.value] || { label: 'Select', value: null }

                                    setModel(_element.label);
                                    setModelId(_element.value);
                                    setTrimId(null);
                                    setEngineId(null);
                                    set_data_api({
                                        ...data_api,
                                        trims: [],
                                        engines: []
                                    });
                                    fnUpdateCarData({
                                        year,
                                        api: 'trims',
                                        make_id: brandId,
                                        make_model_id: _element.value
                                    });
                                }}
                                value={data_api.models.findIndex(e => e.value === modelId)}
                            >
                                <option key='0'>Select</option>
                                {
                                    data_api.models.map((model, index) => (
                                        <option key={model.value} value={index}>{model.label}</option>
                                    ))
                                }
                            </Form.Select>
                    }
                    {/*
                    <Typeahead
                        id="model"
                        ref={typeaheadRefModel}
                        onChange={(selected) => {
                            setModel(selected[0]?.label);
                            setModelId(selected[0]?.value);
                            typeaheadRefTrims.current.clear();
                            typeaheadRefEngine.current.clear();
                            setTrimId(null);
                            setEngineId(null);
                            fnUpdateCarData({
                                year,
                                api: 'trims',
                                make_id: brandId,
                                make_model_id: selected[0]?.value
                            });
                        }}
                        options={data_api.models}
                        placeholder={t('common.model')}
                        selected={data_api.models.filter(e => e.value === modelId)}
                    />
                    */}
                </Col>

            </Row>

            <Row
                className='mt-1'
            >

                <Col>

                    {
                        data_api.trims.length === 0 ?

                            <div style={styles.lbl}>
                                Trim
                                {fetchingData === 'trims' && <>&nbsp;<Spinner animation="grow" size="sm" /></>}
                            </div>
                            :


                            <Form.Select
                                aria-label="Vehicle Trim"
                                onChange={(e) => {
                                    let _element = data_api.trims[e.target.value] || { label: 'Select', value: null }

                                    setTrim(_element.label);
                                    setTrimId(_element.value);
                                    setEngineId(null);
                                    set_data_api({
                                        ...data_api,
                                        engines: []
                                    });
                                    fnUpdateCarData({
                                        year,
                                        api: 'engines',
                                        make_id: brandId,
                                        make_model_id: modelId,
                                        make_model_trim_id: _element.value
                                    });
                                }}
                                value={data_api.trims.findIndex(e => e.value === trimId)}
                            >
                                <option key='0'>Select</option>
                                {
                                    data_api.trims.map((trim, index) => (
                                        <option key={trim.value} value={index}>{trim.label}</option>
                                    ))
                                }
                            </Form.Select>
                    }

                    {/* <Typeahead
                        id="trims"
                        ref={typeaheadRefTrims}
                        onChange={(selected) => {
                            setTrim(selected[0]?.label);
                            setTrimId(selected[0]?.value);
                            typeaheadRefEngine.current.clear();
                            setEngineId(null);
                            fnUpdateCarData({
                                year,
                                api: 'engines',
                                make_id: brandId,
                                make_model_id: modelId,
                                make_model_trim_id: selected[0]?.value
                            });
                        }}
                        options={data_api.trims}
                        placeholder={t('common.trim')}
                        selected={data_api.trims.filter(e => e.value === trimId)}
                    />
                    */}
                </Col>


            </Row>
            <Row
                className='mt-1'
            >
                <Col
                >
                    {
                        data_api.engines.length === 0 ?

                            <div style={styles.lbl}>
                                Engine
                                {fetchingData === 'engines' && <>&nbsp;<Spinner animation="grow" size="sm" /></>}
                            </div>
                            :


                            <Form.Select

                                aria-label="Engine"
                                onChange={(e) => {

                                    let _element = data_api.engines[e.target.value] || { label: 'Select', value: null }
                                    setEngine(_element.label);
                                    setEngineId(_element.value);
                                }}
                                value={data_api.engines.findIndex(e => e.value === engineId)}
                            >
                                <option key='0'>Select</option>
                                {
                                    data_api.engines.map((engine, index) => (
                                        <option key={engine.value} value={index}>{engine.label}</option>
                                    ))
                                }
                            </Form.Select>
                    }
                    {/* 
                    <Typeahead
                        id="engine"
                        ref={typeaheadRefEngine}
                        onChange={(selected) => {
                            setEngine(selected[0]?.label);
                            setEngineId(selected[0]?.value);
                        }}
                        options={data_api.engines}
                        placeholder={t('common.engine')}
                        selected={data_api.engines.filter(e => e.value === engineId)}
                    />
                    */}
                </Col>
            </Row>
            <Row
                className='mt-1'
            >
                <Col>
                    <Form.Control
                        placeholder={t('common.part_name')}
                        value={partName}
                        onChange={(e) => { setPartName(e.target.value) }}

                        aria-label="Part Name"
                        aria-describedby="basic-addon1"
                    />
                </Col>
            </Row>
            <Row
                className='mt-1'
            >
                <Col>
                    <Form.Control
                        placeholder={t('common.part_description')}
                        value={partDescription}
                        onChange={(e) => { setPartDescription(e.target.value) }}
                        aria-label="Part Description"
                        aria-describedby="basic-addon1"
                    />
                </Col>
            </Row>
            <Row
                className='mt-1'
            >

                <Col>
                    <InputGroup>
                        <InputGroup.Text id="camera-icon" onClick={() => { alert("Camera") }}>
                            <IoCamera size={22} />
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="VIN"
                            value={VIN}
                            onChange={(e) => { setVIN(e.target.value) }}
                            aria-label="VIN"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Container>



    </>

}

const QuotePartList = ({
    partsArray,
    onClick

}) => {
    return <Container

    >
        {partsArray.map((part, index) => {

            return <Card
                key={index}
                className='mt-1 p-2 flex-row align-items-center'
                as={"a"}
                style={{ cursor: "pointer" }}
                onClick={() => { onClick(index) }}
            >
                <Badge bg="primary" className='me-2'>{index + 1}</Badge>
                {part.brand} {part.model} {part.year}
                <br />
                {part.partName}

            </Card>
        })}
    </Container >
}

const QuoteRequestForm = (
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const {
        token,
    } = routeParams;
    const { t } = useTranslation();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        quote_part_updated,
        updating_token
    } = dataReducer;

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const handleCloseConfirmation = () => setShowConfirmation(false);
    const handleShowConfirmation = () => setShowConfirmation(true);

    const [partsArray, setPartsArray] = React.useState(JSON.parse(localStorage.getItem('partsArray') || "[]"), []);
    const [partIndex, setPartIndex] = React.useState();
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setPartIndex(index);
        setShow(true);
    };


    //save itemArray to local storage every time it changes
    React.useEffect(() => {
        localStorage.setItem('partsArray', JSON.stringify(partsArray));
    }, [partsArray]);


    const fnClearPartsArray = () => {
        setPartsArray([]);
        localStorage.setItem('partsArray', JSON.stringify([]));
    }


    const fnAddPart = ({
        partName,
        year,
        brand,
        model,
        trim,
        engine,
        partDescription,
        VIN,

        brandId,
        modelId,
        trimId,
        engineId

    }) => {
        setPartsArray([...partsArray, {
            partName,
            year,
            brand,
            model,
            trim,
            engine,
            partDescription,
            VIN,


            brandId,
            modelId,
            trimId,
            engineId
        }]);
    }


    //if (updating_token) return <Loading />;
    return <Container
        style={{
            padding: 0,

        }}
    >
        <Header />

        {updating_token
            ?
            <Loading />
            :
            <>
                <Container
                    style={{
                        maxWidth: 650,
                        padding: 10
                    }}
                >

                    <h5 style={{
                        textAlign: 'center'
                    }}>{t('account.quote_request')}</h5>


                    <QuotePartList
                        partsArray={partsArray}
                        onClick={(index) => {
                            dispatch({
                                type: 'QUOTE_PART_UPDATE',
                                quote_part_updated: {}
                            });
                            handleShow(index);
                        }}

                    />







                    <Modal
                        show={showConfirmation}
                        onHide={handleCloseConfirmation}
                        centered
                        size="sm"
                        backdrop="static"
                    >

                        <Modal.Header closeButton>
                            <Trans>{t('account.quote_submit')}</Trans>
                        </Modal.Header>

                        <Modal.Footer>


                            <Button variant="secondary" onClick={handleCloseConfirmation}>
                                Cancel
                            </Button>
                            <Button variant="success" onClick={() => {

                                dispatch({
                                    type: 'QUOTE_REQUEST_SUBMIT',
                                    payload: {
                                        token,
                                        body: {
                                            partsArray
                                        }
                                    }
                                });
                                fnClearPartsArray();
                                handleCloseConfirmation();
                                navigate(`/account/${token}/quote/success`);
                            }}>OK</Button>
                        </Modal.Footer>
                    </Modal>








                    <Modal show={show} onHide={handleClose}
                        backdrop="static" >
                        <Modal.Header closeButton>
                            <Modal.Title>Quote Part</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                        >
                            {
                                partIndex === -1 ?
                                    <PartForm
                                        fnAddPartToQuote={fnAddPart}
                                        part={{}}
                                        token={token}
                                    />

                                    :
                                    <PartForm
                                        fnUpdatePart={(part) => {
                                            partsArray[partIndex] = part;
                                            setPartsArray([...partsArray]);
                                        }}
                                        part={partsArray[partIndex]}
                                        token={token}
                                    />
                            }

                        </Modal.Body>
                        {partIndex === -1 ?
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => {

                                    console.log(quote_part_updated)
                                    let errors = [];
                                    if (!quote_part_updated.trimId) {

                                        errors.push(t('account.trim_required'));

                                    }

                                    if (quote_part_updated.partName === "") {
                                        errors.push(t('account.part_name_required'));

                                    }
                                    if (errors.length) {
                                        alert(errors.join('\n'))
                                        return;
                                    }


                                    fnAddPart(quote_part_updated)
                                    handleClose();
                                }}>
                                    {t('common.add_part')}
                                </Button>
                            </Modal.Footer>
                            :

                            <Modal.Footer>
                                <Button variant="danger" onClick={() => {
                                    partsArray.splice(partIndex, 1);
                                    setPartsArray([...partsArray]);
                                    handleClose();
                                }} >
                                    {t("common.delete")}
                                </Button>
                                <Button variant="primary" onClick={() => {

                                    partsArray[partIndex] = quote_part_updated;
                                    setPartsArray([...partsArray]);
                                    handleClose();
                                }}>
                                    {t("common.save")}
                                </Button>
                            </Modal.Footer>
                        }
                    </Modal>






                </Container>


                {partsArray.length === 0 ?
                    <Container>
                        <Alert variant="success"
                            style={{
                                maxWidth: 350,
                                margin: 'auto'
                            }}
                        >

                            <p>
                                <Trans i18nKey="account.multiple_part_quote_request" />

                            </p>

                            <div
                                className='div-row-space-between'
                            >
                                <Button
                                    variant="secondary"
                                    onClick={() => navigate(`/account/${token}/quotes`)}
                                >{t('account.support')}</Button>
                                <Button
                                    onClick={() => {
                                        setPartIndex(-1);
                                        setShow(true);
                                    }}
                                >
                                    {t('common.add_part')}
                                </Button>
                            </div>
                        </Alert>

                    </Container>
                    :
                    <div className='div-row-space-between' style={{
                        padding: 10,
                        maxWidth: 600,
                        margin: 'auto'
                    }}>

                        <Button
                            variant="primary"
                            onClick={() => {
                                setPartIndex(-1);
                                setShow(true);
                            }}
                        >
                            {t('common.add_part')}
                        </Button>

                        <Button
                            variant="success"
                            onClick={handleShowConfirmation}
                        >
                            Continue
                        </Button>
                    </div>
                }


            </>

        }


    </Container >



}
export default QuoteRequestForm;

const styles = {
    lbl: {
        padding: '7px 14px',
        fontStyle: 'italic',
    }
}