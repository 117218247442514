import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Header from '@Components/Header';
import Footer from '@Components/Footer';
import Loading from '@Components/Loading';
const Content = React.lazy(() => import('./AutoBrandsContent'));

const AutoBrands = () => {
    const { t } = useTranslation();

    return <Suspense><Container
        style={{
            padding: 0
        }}
    >
        <HelmetProvider>
            <Helmet>
                <title>{"ALANI360 " + t('faq.title')}</title>
            </Helmet>
        </HelmetProvider>

        <Header />
        <Container >
            <h1>{t('common.auto_brands')}</h1>
        </Container>
        <React.Suspense fallback={<Loading />}>
            <Content />
        </React.Suspense>
    </Container>
        <Footer />
    </Suspense>

}
export default AutoBrands;