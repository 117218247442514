import React, { Suspense } from 'react';
import { useTranslation, } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { IoLogoWhatsapp } from "react-icons/io5";

import Modal from 'react-bootstrap/Modal';
import Footer from '@Components/Footer';
import Header from '@Components/Header';
import Loading from '@Components/Loading';

const Content = React.lazy(() => import('./AlaniGarageContent'));
const AlaniGarage = () => {
    const { t } = useTranslation();

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <Suspense><Container
        style={{
            padding: 0
        }}
    >
        <HelmetProvider>
            <Helmet>
                <title>{"ALANI360 " + t('faq.title')}</title>
            </Helmet>
        </HelmetProvider>

        <Header />
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
            <h1>{t('faq.title')}</h1>

            <IoLogoWhatsapp onClick={handleShow} size={25} />
        </Container>



        <React.Suspense fallback={<Loading />}>
            <Content />
        </React.Suspense>
        <Modal show={show} centered onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <img
                    src="/alaniO.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Alani360 logo"
                />
                <Modal.Title>ALANI Garage</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className='d-flex flex-column justify-content-center align-items-center'
                style={{ minHeight: 120 }}
            >

                <center>

                    <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" style={{ textShadow: '2px', color: 'blue' }} className='div-row' >
                        {t('common.contact')}
                    </a>

                </center>

            </Modal.Body>
        </Modal>
    </Container>
        <Footer />
    </Suspense>

}
export default AlaniGarage;