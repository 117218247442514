import './App.css';

function App() {
  return (
    <div className="div-col" style={{ paddingTop: 50 }}>
      <div>
        <img
          style={{ width: 150, }}
          src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))" alt="Alani360 logo"

        />
        <div style={{
          textAlign: 'center',
        }}>
          <h1>404</h1>
        </div>
      </div>
    </div>
  );
}

export default App;
