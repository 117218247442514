
import React from 'react';

import Nav from 'react-bootstrap/Nav';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

const Navigator = ({
    activeKey
}) => {
    const { t } = useTranslation();
    const routeParams = useParams();
    const {
        token
    } = routeParams;


    return <Container
        style={{
            padding: '10px 0px',
        }}
    >
        <Nav className="justify-content-center" style={{ fontSize: 14 }} activeKey={activeKey} variant="tabs" >
            <Nav.Item>
                <LinkContainer to={`/account/${token}/`}>
                    <Nav.Link eventKey="main" >{t('account.title')}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            {/*
            <Nav.Item>
                <LinkContainer to={`/account/${token}/payment-information`}>
                    <Nav.Link eventKey="payment-information" >{t('account.payment_information')}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            */}
            <Nav.Item>
                <LinkContainer to={`/account/${token}/packages`}>
                    <Nav.Link eventKey="orders" >{t('account.orders')}</Nav.Link>
                </LinkContainer>
            </Nav.Item>

            <Nav.Item>
                <LinkContainer to={`/account/${token}/quotes`}>
                    <Nav.Link >{t('account.quotes')}</Nav.Link>
                </LinkContainer>
            </Nav.Item>

            <Nav.Item>
                <LinkContainer to={`/account/${token}/tickets`}>
                    <Nav.Link >{t('account.tickets')}</Nav.Link>
                </LinkContainer>
            </Nav.Item>

        </Nav>
    </Container>
}

export default Navigator;