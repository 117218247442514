import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import Header from '@Components/Header';
import { Trans } from 'react-i18next';

import Alert from 'react-bootstrap/Alert';
import { IoLogoWhatsapp, } from "react-icons/io5";

const Expired = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const {
        token
    } = routeParams;

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        token_info,
    } = dataReducer;



    React.useEffect(() => {
        dispatch({
            type: 'TOKEN_INFO_FETCH',
            payload: {
                token
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (token_info?.success) {
            navigate(searchParams.get("back"))
        }
    }, [token_info]); // eslint-disable-line react-hooks/exhaustive-deps


    return <Suspense fallback={<div>Loading...</div>}>
        <Container
            style={{
                padding: 0
            }}
        >
            <Header />

            <Container >

                <Alert variant="danger" className='alert-messages' >
                    <p>
                        <Trans i18nKey="account.invalid_token" />
                        <br /><br />
                        <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" size={14}    ><IoLogoWhatsapp />&nbsp;+18634519550</a>


                    </p>
                </Alert>


            </Container>
        </Container>

    </Suspense >

}
export default Expired;