import React from 'react';
import Container from 'react-bootstrap/Container';

import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header from '@Components/HeaderAccount';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

//import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { IoLogoWhatsapp, IoCheckmarkCircle } from "react-icons/io5";
import { api } from '../api'
import Address from '@Components/Address';

import moment from 'moment-timezone';
import 'moment/locale/pt';
import 'moment/locale/es';

const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}
const fn_formatPrice = (price, currency, locale) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency

    }).format(price);

    return price_formatted;

};
const fnIsEmail = (email) => {
    if (!email) return false;
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}



const Quote = ({
    token,
    quote_id
}) => {
    const { t } = useTranslation();
    moment.locale(localStorage.getItem("lng") || "en")
    //const dataReducer = useSelector((state) => state.dataReducer);
    //const dispatch = useDispatch();
    //const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        account_fields_uddated
    } = dataReducer;

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fetching, setFetching] = React.useState(false);
    const [quote, setQuote] = React.useState({});


    const [approving, setApproving] = React.useState(false);

    //const [units, setUnits] = React.useState(1);

    const fnFetchQuote = async () => {
        setFetching(true);
        let res = await api.quote_fetch({
            payload: {
                token,
                quote_id
            }
        })
        setFetching(false);
        setQuote(res.data);
    }

    React.useEffect(() => {
        if (fetching) return;
        fnFetchQuote();

    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    if (fetching) return <Container
        className='div-col'
        style={{
            marginTop: 50
        }}
    >
        <Spinner size='lg' />

    </Container>


    if (!quote?.autopart_name) {
        return <Container
            className='div-col'
            style={{
                marginTop: 50
            }}
        >

            <center>
                <p>
                    <Trans i18nKey="account.no_quote" />
                </p>


                <a href="https://wa.me/18634519550?text=ALANI!" target="_blank" rel="noreferrer" size={14} style={{ textShadow: '2px', color: 'blue' }}   ><IoLogoWhatsapp />&nbsp;+18634519550</a>
            </center>
        </Container>
    }
    return <>
        <Container>
            <Card
                style={{ margin: '20px auto', marginTop: 20, maxWidth: '25rem' }}
                size='sm'
            >
                <Card.Body>

                    <Card.Title>
                        {quote?.autopart_name}
                    </Card.Title>
                    <Card.Subtitle>

                        <div>{quote?.autopart_number}</div>
                        <small>{titleCase(moment(quote.quote_datetime).format("dddd, MMMM DD HH:mm"))}</small>

                    </Card.Subtitle>

                    <Card.Text>
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <b>{fn_formatPrice(quote?.quote_amount, 'USD', 'en-US')}</b>
                            <br />
                            15 {t('account.days')}
                        </div>
                    </Card.Text>
                    <Card.Img
                        variant='bottom'
                        style={{
                            maxHeight: 180,
                            minHeight: 100,
                            width: 'auto',
                            margin: 'auto',
                            display: 'block'
                        }}
                        src={quote?.autopart_photo_url}
                    />


                </Card.Body>
                <Card.Body>
                    <Card.Text>
                        {quote?.autopart_fitment}
                    </Card.Text>
                </Card.Body>

                {quote.payment_url === null
                    &&
                    <Card.Footer
                        className='div-row'
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="primary"
                            style={{
                                width: 250
                            }}
                            onClick={() => {
                                handleShow()
                            }}
                        >{t('account.continue_payment')}</Button>
                    </Card.Footer>
                }

                {quote.payment_url !== null
                    &&
                    !quote.payment_authorized_dt
                    &&
                    <Card.Footer
                        className='div-row'
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button variant="primary"
                            style={{
                                width: 250
                            }}
                            onClick={() => {
                                window.location.href = quote.payment_url;
                            }}
                        >{t('account.continue_payment')}</Button>
                    </Card.Footer>

                }


                {
                    !!quote.payment_authorized_dt
                    &&
                    <Card.Footer
                        style={{
                            alignContent: 'center',
                        }}
                    >
                        <IoCheckmarkCircle color="green" size={20} />&nbsp;{t('account.order_placed')}
                        <br />
                        <small>{titleCase(moment(quote.quote_approved).format("dddd, MMMM DD HH:mm"))}</small>
                    </Card.Footer>

                }

            </Card>
        </Container>
        <Modal
            show={show}
            onHide={handleClose}
            animation={false}
        >
            <Modal.Header closeButton>
                <img
                    src="/alaniO.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Alani360 logo"
                />
                <Modal.Title>&nbsp;&nbsp;{t("common.confirm")}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className='d-flex flex-column justify-content-center align-items-center'

            >

                <Address
                    token={token}
                />

                <center>

                    <Trans i18nKey="account.buy_confirm" />

                </center>

                {/*<ButtonGroup aria-label="Units" style={{ margin: 10 }}>
                    <Button variant="primary" style={{ width: 50 }} onClick={() => units > 1 ? setUnits(units - 1) : 1}>-</Button>
                    <Button variant="light" style={{ width: 50 }}>{units}</Button>
                    <Button variant="primary" style={{ width: 50 }} onClick={() => setUnits(units + 1)}>+</Button>
                </ButtonGroup>
            {quote?.quote_amount * units}*/}
            </Modal.Body>
            <Modal.Footer>
                <div
                    style={{
                        width: '100%'
                    }}
                    className='div-row-space-between'
                >
                    <Button variant="secondary"
                        disabled={approving}
                        onClick={handleClose}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button variant="success"
                        style={{ width: 250 }}
                        disabled={approving}
                        onClick={async () => {


                            let errors = [];
                            if (
                                (account_fields_uddated?.email || "") === ""
                                ||
                                !fnIsEmail(account_fields_uddated?.email)
                            ) {
                                errors.push(t('account.email_required'))
                            }

                            if ((account_fields_uddated?.address || "") === "") {
                                errors.push(t('account.address_required'))
                            }
                            if (errors.length > 0) {
                                alert(errors.join("\n"))
                                return;
                            }

                            setApproving(true);
                            let res = await api.quote_approve({
                                payload: {
                                    token,
                                    quote_id,
                                    shipping_address: account_fields_uddated
                                }
                            });

                            if (res.redirect_url) {
                                window.location.href = res.redirect_url;
                            } else {

                                setApproving(false);
                                await fnFetchQuote();
                                handleClose();
                            }
                        }}
                    >
                        {approving ?
                            <Spinner size='sm' /> :

                            t('account.continue_payment')
                        }
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
}


const AutopartQuote = (

) => {
    const routeParams = useParams();
    const {
        token,
        quote_id
    } = routeParams;

    const dataReducer = useSelector((state) => state.dataReducer);
    const { token_info, } = dataReducer; // eslint-disable-line

    /*
    TODO: not needed ????
    React.useEffect(() => {
        dispatch({
            type: 'TOKEN_INFO_FETCH',
            payload: {
                token
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    */



    return <Container
        style={{
            padding: 0
        }}
    >
        <Header />

        <Quote
            token={token}
            quote_id={quote_id}
        />

    </Container>



}
export default AutopartQuote;