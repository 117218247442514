import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useParams, useNavigate } from 'react-router-dom';

import { LinkContainer } from 'react-router-bootstrap'
import { IoLogoWhatsapp, IoLogOutOutline, } from "react-icons/io5";

import { useDispatch } from 'react-redux';
const AccountHeader = () => {
    const { t, i18n } = useTranslation();
    const [lng, setLng] = React.useState(localStorage.getItem("lng") || "en");
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const routeParams = useParams();
    const {
        token
    } = routeParams;
    React.useEffect(() => {
        let _lng =
            (
                window.location.host === 'alanigaragebrasil.com.br'
                ||
                window.location.host === 'alanigarage.com.br'
                ||
                window.location.host === 'www.alanigarage.com.br'
                ||
                window.location.host === 'www.alanigaragebrasil.com.br'

            ) ? "pt"
                : window.location.host === 'alanigarage.com.mx' ? "es" : lng;

        localStorage.setItem("lng", _lng);
        i18n.changeLanguage(_lng);
        setExpanded(false);
    }, [lng]); // eslint-disable-line react-hooks/exhaustive-deps




    React.useEffect(() => {
        dispatch({
            type: 'TOKEN_INFO_FETCH',
            navigate,
            payload: {
                token
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    return <>


        <Navbar bg="light" expand="xxl" fixed="top" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            <Container
                style={{
                    maxWidth: 800
                }}
            >
                <LinkContainer to={`/account/${token || ""}`}>
                    <Navbar.Brand href="#home" className='div-row'>

                        <img
                            src="/alaniO.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Alani360 logo"
                        />
                        &nbsp;
                        <h4 style={{ margin: 0, fontWeight: 'normal' }}>ALANI Garage</h4>
                        &nbsp;
                        {lng === 'pt' &&
                            <img
                                src="/br.svg"
                                width="25"
                                alt="Alani360 logo"
                            />
                        }

                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav"  >
                    <Nav className="me-auto">

                        <Nav.Link href="https://wa.me/18634519550?text=ALANI" target="_blank" rel="noreferrer" style={{ textShadow: '2px' }} className='div-row' ><IoLogoWhatsapp />&nbsp;WhatsApp</Nav.Link>


                        <LinkContainer to={`/home/${token || ""}`}>
                            <Nav.Link
                                className='div-row'
                            >{t('common.Home')}</Nav.Link>
                        </LinkContainer>

                        {!!token && <>



                            <LinkContainer to={`/account/${token}`} >
                                <Nav.Link
                                    className='div-row'
                                >{t('account.title')}</Nav.Link>
                            </LinkContainer>


                            <Nav.Link to="/" onClick={() => {
                                if (window.confirm("Are you sure you want to logout?")) {
                                    /*localStorage.removeItem("token");
                                    localStorage.removeItem("user");*/
                                    dispatch({
                                        type: 'LOGOUT',
                                        payload: {
                                            token
                                        }
                                    });
                                    navigate(`/home`);
                                }
                            }} href="#"
                                className='div-row'
                            ><IoLogOutOutline />&nbsp;{t('account.logout')}</Nav.Link>

                        </>
                        }

                        {
                            window.location.host !== 'alanigaragebrasil.com.br'
                            && window.location.host !== 'alanigarage.com.mx'
                            && window.location.host !== 'alanigarage.com.br'
                            && window.location.host !== 'www.alanigarage.com.br'
                            && window.location.host !== 'www.alanigaragebrasil.com.br'

                            &&
                            <NavDropdown title={lng} id="basic-nav-dropdown" style={{ maxWidth: 100 }}>
                                <NavDropdown.Item href="#" onClick={() => setLng('es')} >Español</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => setLng('pt')}>
                                    Portugues
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => setLng('en')}>English</NavDropdown.Item>

                            </NavDropdown>
                        }

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container style={{ height: 60 }}></Container>

    </>


}

export default AccountHeader;